<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { Helper } from '../../../../helper.js';
import Config from "../../../../config.js";

export default {
  mixins:[Helper],
  components: { 
    Layout,
    PageHeader,
    // Multiselect,
  },
  data() {
    return {
      userStart:JSON.parse(localStorage.getItem('user')),
      data_url:Config,
      errors:[],
      input_form:{
        id:"",
        name:"",
        description:""
      },
      dataProvinsi:[],

      tab:1,
      title: this.$root.$t('pageKarya.howpay.title'),
      loading:false,
      items: [
        {
          text: this.$root.$t('pageKarya.howpay.text_menu_top_1'),
        },
        {
          text: this.$root.$t('pageKarya.howpay.text_menu_top_2'),
          active: true
        }
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "reference", sortable: true, label: this.$root.$t('pageKarya.howpay.table.th0') },
        { key: "name", sortable: true, label: this.$root.$t('pageKarya.howpay.table.th1') },
        { key: "description", sortable: true, label:  this.$root.$t('pageKarya.howpay.table.th2') },
        { key: "action" }
      ]
    };
  },
  computed: {
    rows() {
      return this.ordersData.length;
    },
  },
  mounted() {
    this.ApiCallData();
    this.totalRows = this.ordersData.length;
  },
  methods: {

    cancelEdit(){
      this.input_form = {
        id:"",
        name:"",
        description:""
      }
    },

    editRecord(data){
      this.tab = 2;
      if (Config.modeDev) console.log(data);
      
      this.input_form = {
        id:data.item.id,
        name:data.item.name,
        description:data.item.description,
      }
    },

    deleteRecord(data) {
      this.$bvModal
        .msgBoxConfirm("Are you sure wants to delete?", {
          title: "Please Confirm",
          size: "mm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.ApiDeleteData(data);
          }
        });
    },

    async ApiDeleteData(data){

      this.$loading.show({delay:0});

      let formData = new URLSearchParams(); 
      formData.append('id', data.item.id); 
      await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.deleteCaraPembayaran, formData, {
        headers:{
            "Forca-Token": this.userStart.token,
            "Accept": "application/json",
            //"Content-Type": "multipart/form-data" // "application/x-www-form-urlencoded" // "text/html"
        }
        }).then(res => {            
            if (Config.modeDev) console.log(res);
            if( res.data.codestatus == "S" ){

              this.$notify({
                group: 'foo',
                title: 'Success',
                text: 'Delete Data Sector Business'
              });

              this.ApiCallData();

            }else{  
              this.wrongTokenCo(res)
            }

        }).catch( ( error ) => {
            if(this.is_runTest == false){ console.log(error) }
            try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
          
        } );

        this.$loading.hide();

    },

    async ApiInputData(){

      this.loading = true;

      this.errors = [];

      if (!this.input_form.name) {
        this.errors.push("Name required.");
      }

      if (this.errors.length == 0) {
       
        let formData = new URLSearchParams(); 
        formData.append('name', this.input_form.name); 
        formData.append('description', this.input_form.description); 
      
        // console.log(JSON.stringify(this.input_form));
        // let data = JSON.parse(JSON.stringify(this.input_form));
        if( this.input_form.id != "" ){
          formData.append('id', this.input_form.id); 
        }
        // console.log(this.input_form);
        // console.log(data)
        // console.log( JSON.stringify( data ) );
        // formData.append('json', JSON.stringify( data ) ); 

        await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.insertCaraPembayaran, formData, {
        headers:{
            "Forca-Token": this.userStart.token,
            "Accept": "application/json",
            //"Content-Type": "multipart/form-data" // "application/x-www-form-urlencoded" // "text/html"
        }
        }).then(res => {            
            if (Config.modeDev) console.log(res);
            if( res.data.codestatus == "S" ){
              if (Config.modeDev) console.log("Berhasil");
              this.$notify({
                group: 'foo',
                title: 'Success',
                text: 'Save Data Customer'
              });

              this.cancelEdit();
              this.ApiCallData();
            }else{  
              this.wrongTokenCo(res)
            }

        }).catch( ( error ) => {
            if(this.is_runTest == false){ console.log(error) }

            try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
        } );

        this.input_form.id = "";
        this.loading = false;

      }
     

    },

    async ApiCallData(){

      this.loading = true;
      let formData = new URLSearchParams(); 
      formData.append('perpage', 9999); 
      formData.append('page', 1); 

      if (Config.modeDev) console.log(this.userStart);
      await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.getCaraPembayaran, formData, {
      headers:{
          "Forca-Token": this.userStart.token
          // "Accept": "application/json",
          // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
      }
      }).then(res => {            
          if (Config.modeDev) console.log(res);
          if( res.data.codestatus == "S" ){
              this.ordersData = res.data.resultdata;
              this.totalRows = this.ordersData.length;
          }else{
           
            this.wrongTokenCo(res)
          }

      }).catch( ( error ) => {
          if(this.is_runTest == false){ console.log(error) }

          try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
      } );

      this.loading = false;

      },

          /**
          * Search the table data with search input
          */
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        }
      }
    };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab :active="tab == 1" @click="tab = 1" title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">{{ $t('pageKarya.howpay.tab_title_view')}}</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <!-- <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
                            'badge-soft-success': `${row.value}` === 'Paid',
                            'badge-soft-warning': `${row.value}` === 'Unpaid'}"
                      >{{ row.value}}</div>
                    </template> -->
                    <!-- <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template> -->
                    <template v-slot:cell(action)="data">
                      <a
                        @click="editRecord(data)"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        @click="deleteRecord(data)"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab :active="tab == 2" @click="tab = 2" title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">{{ input_form.id == "" ? $t('pageKarya.howpay.tab_title_add') :  $t('pageKarya.howpay.tab_title_edit') }}</a>
                </template>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="">
                      <div class="">
                        <!-- <h4 class="card-title">Add Customer</h4>
                        <p class="card-title-desc">
                          Please, Input data
                        </p> -->
                        <div class="row">
                          <div class="col-12">
                              <p v-if="errors.length">
                                <span class="text-danger" ><b>{{ $t("pageKarya.howpay.form.error") }}</b></span>
                                <ul>
                                  <li class="text-primary" v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
                                </ul>
                              </p>

                              <input type="hidden" name="id" v-model="input_form.id" />
                              <b-form-group
                                id="example text"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                :label=" $t('pageKarya.howpay.field.field1') + ' *' "
                                label-for="text"
                              >
                                <b-form-input for="text" value="" v-model="input_form.name"></b-form-input>
                              </b-form-group>

                              <b-form-group
                                id="example text"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                :label=" $t('pageKarya.howpay.field.field2') "
                                label-for="text"
                              >
                                <b-form-input for="text" value="" v-model="input_form.description"></b-form-input>
                              </b-form-group>

                              <b-button class="float-right" @click="ApiInputData()" variant="primary">{{ $t("pageKarya.howpay.button.button1") }}</b-button>

                              <b-button class="float-right mr-3" @click="cancelEdit()" v-show="this.input_form.id != ''" variant="warning">{{ $t("pageKarya.howpay.button.button2") }}</b-button>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>